* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  /*background-color: #111316;*/
}

/* @font-face {
    font-family: "HelveticaNeueLTPro-Bd";
    src: url("webFonts/HelveticaNeueLTProBd/font.woff2") format("woff2"),
        url("webFonts/HelveticaNeueLTProBd/font.woff") format("woff");
}

@font-face {
    font-family: "HelveticaNeueLTPro-Roman";
    src: url("webFonts/HelveticaNeueLTProRoman/font.woff2") format("woff2"),
        url("webFonts/HelveticaNeueLTProRoman/font.woff") format("woff");
}

@font-face {
    font-family: "HelveticaNeueLTPro-It";
    src: url("webFonts/HelveticaNeueLTProIt/font.woff2") format("woff2"),
        url("webFonts/HelveticaNeueLTProIt/font.woff") format("woff");
} */

@font-face {
  font-family: 'Class Display';
  font-weight: normal;
  src: url('webFonts/ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Satoshi Complete';
  font-weight: 400;
  src: url('webFonts/Satoshi_Complete/Fonts/WEB/fonts/Satoshi-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Satoshi Complete';
  font-weight: 700;
  src: url('webFonts/Satoshi_Complete/Fonts/WEB/fonts/Satoshi-Bold.woff2')
    format('woff2');
}

@font-face {
  font-family: 'Satoshi Complete';
  font-weight: 500;
  src: url('webFonts/Satoshi_Complete/Fonts/WEB/fonts/Satoshi-Medium.woff2')
    format('woff2');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 500;
  src: url('webFonts/SF_Pro_Display/SFPRODISPLAYREGULAR.OTF') format('otf'),
    url('webFonts/SF_Pro_Display/SFPRODISPLAYREGULAR.ttf') format('ttf'),
    url('webFonts/SF_Pro_Display/SFPRODISPLAYREGULAR.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 500;
  src: url('webFonts/SF_Pro_Display/SFPRODISPLAYMEDIUM.OTF') format('otf'),
    url('webFonts/SF_Pro_Display/SFPRODISPLAYMEDIUM.ttf') format('ttf'),
    url('webFonts/SF_Pro_Display/SFPRODISPLAYMEDIUM.woff2') format('woff2');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 700;
  src: url('webFonts/SF_Pro_Display/SFPRODISPLAYBOLD.OTF') format('otf'),
    url('webFonts/SF_Pro_Display/SFPRODISPLAYBOLD.ttf') format('ttf'),
    url('webFonts/SF_Pro_Display/SFPRODISPLAYBOLD.woff2') format('woff2');
}

.full-range {
  width: 100px;
  height: 18px;
  background-color: #1f1f1f;
  /*     border: 12px solid #EFF2F1; */
  border-radius: 18px;
}

.current-range {
  width: 100px;
  height: 18px;
  border-radius: 18px;
  position: relative;
  margin-top: -18px;
}

/* background: linear-gradient(
      90deg,
      #de3737 0px,
      #d6d94a 50px,
      #1db22c 100px
    ); */

/* background: linear-gradient(90deg, #c5de37 0px, #1db297 100px); */

.current-hiro {
  width: 18px;
  height: 18px;
  position: relative;
  margin-top: -18px;

  background: #eff2f1;
  border: 4.5px solid #8bcf58;
  border-radius: 18px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.gauge {
  display: table-cell;
  vertical-align: middle;
}

.hiro-value {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  display: table-cell;
  vertical-align: middle;
  padding: 5px;
  color: #9aa1af;
}

.tv-lightweight-charts {
  z-index: -1 !important;
}

canvas {
  z-index: 0 !important;
}

.indices-chart-container,
.hiro-chart-container,
.indices-chart-wrapper,
.real-time,
.indices-chart {
  height: 100%;
  width: 100%;
  margin-bottom: -25px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(127, 127, 127, 0.25) rgba(127, 127, 127, 0.25);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(127, 127, 127, 0.25);
  border-radius: 20px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Feature: Zoom in  */
.expanded {
  width: 75%;
  display: block;
  position: absolute;
  left: calc(10% - 10px);
  top: calc(10% - 10px);
  margin: 0px auto;
  z-index: 100;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  z-index: 100;
}

.expanded-background {
  background: #00000026;
  z-index: 10;
  position: absolute;
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

path.line {
  fill: transparent;
}

text {
  color: #d2d2d2 !important;
  font-family: 'SF Pro Display' !important;
}

.gamma-value {
  font-size: 10px;
}

a:hover {
  cursor: pointer;
}

.MuiTabPanel-root {
  width: 100%;
  height: 100%;
  padding: 0px;
}

.recharts-label {
  text-anchor: middle;
}

.occ-chart ul.recharts-tooltip-item-list {
  display: flex;
  flex-direction: 'row';
  width: 500px;
  flex-wrap: wrap;
  gap: 3px;
  line-height: 100%;
}

.BaseTable {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-collapse: none;
  border-spacing: 0;
  background-color: transparent;
  border-radius: none;
  box-shadow: none;
}

.current-price {
  border: 2px solid #00000026 !important;
  font-weight: 700 !important;
}

tr.MuiTableRow-root.MuiTableRow-hover.\.MuiTableRow-disabled td,
li.AutoCompleteItem-disabled {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.recharts-label-list text.recharts-text.recharts-label {
  font-size: 12px;
}

.zerohedge-ticker-chip .MuiChip-labelSmall {
  color: #18bdac;
}

.recharts-brush text {
  font-size: 11px;
}

#zendesk-modal img {
  width: 100%;
  height: auto;
}

#zendesk-modal p {
  margin-top: 10px;
  line-height: 125%;
}

#zendesk-modal
  img[alt~='SpotGamma_Subscriptions_Support_Center_Pro_Alpha.png'] {
  width: 100%;
  height: auto;
}

#zendesk-modal
  img[alt~='SpotGamma-Subscriptions-CTA-Banner-Support-Center.png'],
#zendesk-modal img[alt~='SpotGamma-Academy-CTA-Banner-Support-Center.png'] {
  width: 50%;
}
